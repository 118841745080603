import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function Tileset({game, tileset, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	var puzzle = (game == "shuzzle" || game == "pixit" || game == "calendar") ? "Puzzle" : "Level";

	function getUrl({game, tileset}) {
    	return ("/" + "en" + "/" + game + "/" + tileset);
    }

    function playGame({game, tileset, available}) {
	    if(available) {
		    ReactGA.event({

		        category: 'Game',
		        action: 'StartGameFromArticle'
		    });
		    window.location.href = getUrl({game, tileset});
	    }
    }

    const gameImage = (["infinite", "timed", "unpaired"].includes(game)) ? "tiles" : game;
    const bigClass = (["swapoku", "pixit", "calendar", "shuzzle", "lines"].includes(game)) ? "articleTilesetBig" : "";

	var available = false;
	if(date == undefined) {
		available = true;
	} else {
		const todayDate = new Date();
	    const tilesetDate = new Date(date);
	    if(tilesetDate <= todayDate) {
	        available = true;
	    }
	}



    return (
        (<Col className='tilesetContainer articleTilesetContainer'>
            <Card onClick={() => playGame({game, tileset, available})} className={"tilesetCard articleTileset " + bigClass}>
                { game == "calendar"
                    ? <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + gameImage + "/" + tileset + "_closed.png"}  />
                    : <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + gameImage + "/" + tileset + ".png"}  />
				}
                <Card.Body>
                    <Card.Title>{puzzle} {tileset}</Card.Title>
                    {
                        available
                        ? <Button className="small-btn" variant="primary"
                                                      onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                        : <p className="levelNotAvailable">{puzzle} available from {date} </p>

                    }

                </Card.Body>
            </Card>
        </Col>)

    );
}

function Part({name, text, examples}) {
	return (<>
		<h2> {name} </h2>
		<p> {text} </p>
		{
			examples == undefined
			? <></>
			: <Row>
				{
				examples.map(lvl => (
					<Tileset game={lvl["game"]} tileset={lvl["tileset"]} date={lvl["date"]} />
				))
				}
			</Row>
		}
	</>);
}

function GoTo({name, href}) {

	function goToHref() {
		ReactGA.event({
            category: 'Amazon',
    		action: 'GoToAmazon'
    	});
		window.location.href = href;
	}
	return (
		<Button className="default-btn" onClick={() => goToHref()}>{name}</Button>
	);
}

function Image({image, href}) {

	function goToHref() {
		ReactGA.event({
            category: 'Amazon',
    		action: 'GoToAmazon'
    	});
		window.location.href = href;
	}
	return (
		<img className="articleImg" onClick={() => goToHref()} src={image}/>
	)
}

const Article = ({article}) => {

	//article = article.toString();

	var text = require("../static/json/articles/" + article + ".json");


	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>{text["title"]}</title>
            <meta name="description" content={text["title"]} />
            <link rel="canonical" href={"https://www.unlimited-tiles.com/blog/" + article} />
            <meta property="og:url" content={"https://www.unlimited-tiles.com/blog/" + article} />
            <meta property="og:image" content={"https://www.unlimited-tiles.com/images/articles/" + article + ".png"} />
            <meta property="og:title" content={text["title"]} />
            <meta property="og:description" content="Find hidden words "/>
        </Helmet>

        <Container className="article">
            <h1>{text["title"]}</h1>
            <center><h5>{text["subtitle"]}</h5></center>
			{
				text["article"].map(p => (
					p["type"] == "button"
					? <GoTo name={p["name"]} href={p["href"]}/>
					: p["type"] == "image"
						? <Image image={p["image"]} href={p["href"]} />
						: <Part name={p["name"]} text={p["text"]} examples={p["examples"]} key={p["name"]}/>
				))
			}


        </Container>
        </div>
      );

    return tilesetHtml
}

export default Article;

