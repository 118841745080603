//import React from 'react';
import React, { Suspense } from 'react';


import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';

import './style.css';

//import Game from './components/Game';
//import Result from './components/Result';
import Main from './Main';
//import OurMiniGames from './components/OurMiniGames';
//import Minigame from './components/games/Minigame';
//import PlayGame from './components/games/PlayGame';
import Header from './components/Header';
import Footer from './components/footer/Footer';
//import Tilesets from './components/Tilesets';
import ZestawySpodLady from './components/ZestawySpodLady';
import general from './static/json/general.json';
import articles from './static/json/articles.json';

import Pairs from './components/games/Pairs';
import Article from './components/Article';
import Articles from './components/Articles';


import CookiePolicy from './components/footer/CookiePolicy';
import PrivacyPolicy from './components/footer/PrivacyPolicy';
import Terms from './components/footer/Terms';
import Contact from './components/footer/Contact';
import WordSearch from './components/WordSearch';
import Badges from './library/Badges';
import { countStars } from './library/stars.js';


import './i18n';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

const OurMiniGames = React.lazy(() => import("./components/OurMiniGames"));

const Game = React.lazy(() => import("./components/Game"));
const Result = React.lazy(() => import("./components/Result"));
const Tilesets = React.lazy(() => import("./components/Tilesets"));
const Minigame = React.lazy(() => import("./components/games/Minigame"));
const PlayGame = React.lazy(() => import("./components/games/PlayGame"));


function App() {

	const languages = ["en"]; // ["en", "de", "it", "fr"];
    const games = ["tiles", "pairs", "infinite", "same", "timed", "lines", "unpaired", "neighbours", "swapoku", "segments",
    "shuzzle","shuzzle2","calendar","pixit", "sparkit"];
    const tilesgames = ["tiles", "pairs", "infinite", "timed", "unpaired"];

	var generalImproved = {};
	for(var i = 0; i < games.length; i++) {
		var g = games[i];
		var levelsName = tilesgames.includes(g) ? "waiting" : g;
		var tsets = general[levelsName].map(tset => tset.name);
		generalImproved[g] = tsets;
	}

	var arts = articles["articles"].map(tset => tset.name);
	var url = window.location.href;

	if(!url.includes("www") && url.includes("unlimited")) {
		var newUrl = url;
		newUrl = newUrl.replace("unlimited", "www.unlimited");
		if(url != newUrl) {

			window.location.href = newUrl;
		}
	}

	return (
		<>
			<Header/>
			<Router>
				<div className="App">
				<Suspense fallback={<></>}>
                        <Routes>

                            <Route path="/games" element={<OurMiniGames />} />

                            {languages.map(lang =>
				            games.map(game => (
				                <>
						            <Route key={lang} path={`/${lang}/${game}`}
							                element={<Minigame lang={lang} game={game} />}
							        />
						            <Route key={lang} path={`/${lang}/${game}/tilesets`}
							                element={<Tilesets lang={lang} game={game} />}
							        />

						            {generalImproved[game].map(tileset => (
						                <>
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}`}
							                    element={<Game game={game} lang={lang} tileset={tileset} />}
							                />
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}/result`}
							                    element={<Result game={game} lang={lang} tileset={tileset} />}
							                />
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}/play`}
							                    element={<PlayGame lang={lang} game={game} tileset={tileset} />}
							                />
						                </>
						            ))}

						        </>
				            ))
				        )}

				        <Route path="/zestawy_spod_lady" element={<ZestawySpodLady />} />

	                    <Route path="/cookies" element={<CookiePolicy />} />
	                    <Route path="/privacy" element={<PrivacyPolicy />} />
	                    <Route path="/terms" element={<Terms />} />
	                    <Route path="/contact" element={<Contact />} />
	                    <Route path="/wordsearch" element={<WordSearch />} />
	                    <Route path="/badges" element={<Badges />} />






	                    <Route path="/blog" element={<Articles />} />
				        {
				            arts.map(art => (
				                <Route key={art} path={`/blog/${art}`}
                                	element={<Article article={art} />}
                                />
				            ))
				        }

	                    <Route path="/*" element={<Main lang="en" />} />

                        </Routes>
                </Suspense>



            </div>
			</Router>
			<Footer/>

		</>
		//</React.StrictMode>

	);
};


ReactDOM.render(<App />, document.getElementById('root'));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}