import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function goToAmazon(source) {
	console.log(source);
	ReactGA.event({
        category: 'Amazon',
    	action: 'GoToAmazon',
    	label: source
    });
}


function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
        ReactGA.event({
            category: 'Amazon',
        	action: 'WordSearchPlay',
        	value: selectedIndex
        });
    };

    var x = 45;
    var slides = Array(x);
    for(var i = 0; i < x; i++) {
        slides[i] = i+1
    }

    return (
	    <Carousel activeIndex={index} onSelect={handleSelect} slide={false} data-bs-theme="dark" interval={100000}>
	    {
	        slides.map(slide => (
	            <Carousel.Item>
                    <img alt={"word search " + slide} variant="top" src={"/images/wordsearch/spices/" + slide + ".png"}  />
                 </Carousel.Item>
	        ))
	    }

	    </Carousel>
    );
}




const WordSearch = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Twisted Word Search</title>
            <meta name="description" content="Twisted Word Search - modern word search with twisted words" />
            <link rel="canonical" href="https://www.unlimited-tiles.com/wordsearch" />
            <meta property="og:url" content="https://www.unlimited-tiles.com/wordsearch" />
            <meta property="og:image" content="https://www.unlimited-tiles.com/images/wordsearch/spices/1.png" />
            <meta property="og:title" content="Twisted Word Search - modern word search with twisted words"/>
            <meta property="og:description" content="Find hidden words "/>
        </Helmet>

        <Container>
            <h1>Our Word Search Book</h1>
			<p>We're excited to share our new <b>Twisted Word Search</b> book with you! It's packed with 200 puzzles and over
			2,300 words to find, but there's a fun twist—literally! In this book, the words are hidden in unexpected ways,
			making each puzzle a bit more challenging and a lot more fun.</p>


			<a href="https://www.amazon.com/dp/B0DFWN5KN7/" >
				<img className="bigImg" alt="word search book" onClick={()=>goToAmazon("image1")} src="/images/wordsearch/1.png" />
			</a>

			<p>This book has 200 themed puzzles, each with a list of words to find. Unlike regular word searches, the letters can go in any direction—up, down, diagonal, or zigzag—adding a fun twist to the challenge!</p>

			<a href="https://www.amazon.com/dp/B0DFWN5KN7/" >
				<img className="bigImg" alt="word search book" onClick={()=>goToAmazon("image3")} src="/images/wordsearch/3.png" />
			</a>

			<p>As you find the words, the leftover letters reveal a hidden message, adding a little extra surprise at the end of each puzzle.</p>

			<a href="https://www.amazon.com/dp/B0DFWN5KN7/" >
				<img className="bigImg" alt="word search book" onClick={()=>goToAmazon("image2")} src="/images/wordsearch/2.png" />
			</a>

			{
			//			<p> Try it now!</p>
			//			<ControlledCarousel/>
			}




            <p><b>Advantages of Twisted Word Search:</b>
            <ol>
            <li>Boosts cognitive skills by sharpening focus and pattern recognition.</li>
            <li>Expands vocabulary with themed words across various topics.</li>
            <li>Enhances problem-solving with added layers of challenge.</li>
            <li>Improves patience and focus with complex word paths.</li>
            <li>Adds excitement with twists and hidden messages.</li>
            <li>Relieves stress while engaging the mind.</li>
            <li>Creates a sense of achievement with each completed puzzle.</li>
            <li>A great calming activity for cozy autumn evenings.</li>
            </ol>

            </p>

			<p>Perfect for all ages, this book is a great way to relax, challenge your brain, and enjoy some quiet time.
               Grab your copy today and discover a new spin on classic word search puzzles!</p>

            <Button href="https://www.amazon.com/dp/B0DFWN5KN7/" style={{"margin": "auto"}} variant="success" className="default-btn" onClick={() => goToAmazon("button")}>Get Twisted Word Search</Button>




			<br/>
			<br/>
			<br/>

			{/*
			<h1>Twisted Word Search - SPICES</h1>
			<p>If you're not sure if our twisted word search is for you,  watch our video on YouTube.</p>
			<iframe width="320" height="180" src="https://www.youtube.com/embed/TYily5V9SXM?si=F1c7EdvcD_zwttpq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
			*/ }

        </Container>
        </div>
      );

    return tilesetHtml
}

export default WordSearch;

