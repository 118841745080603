import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../../static/css/tilesets.css';



function Minigame({game, type}) {

	const { t } = useTranslation();

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage === "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }

    function textToUpper({game}) {
        if(game === "same") {
            return "SAME GAME";
        }if(game === "spellbee") {
            return "SPELL BEE";
        }if(game === "guesser") {
            return "NUMBER GUESSER";
        }if(game === "ladder") {
            return "WORD LADDER";
        }if(game === "chain") {
            return "WORD CHAIN GAME";
        }if(game === "wordsearch") {
            return "WORD SEARCH";
        }
        return game.toUpperCase();
	}

	function getUrl(type, detectedLanguage, game) {
		if(type === "out") {
			var href = {
				"spellbee": "https://www.spell-bee.com/index.html?utm_source=Tiles",
				"guesser": "https://www.number-guesser.com/index.html?utm_source=Tiles",
				"ladder": "https://www.word-ladder.com/index.html?utm_source=Tiles",
				"chain": "https://www.word-chain.co/index.html?utm_source=Tiles",
				"strands": "https://www.strands-wordle.com/?utm_source=Tiles",
				"wordle": "https://www.infinite-wordle.com/?utm_source=Tiles",
                "wordsearch": "https://www.unlimited-tiles.com/wordsearch"
			}
			return href[game];
		}
		return "/" + detectedLanguage + "/" + game + "/tilesets";
	}

	function getAlt({game}) {
		return game + " game";
	}

    return (
        <Col className='tilesetContainer'>
                    <Card className='tilesetCard' style={{"max-height": "200px"}}>
                        <a href={getUrl(type, detectedLanguage, game)}>
                         {
                            type === "in" ? <Card.Img alt={getAlt({game})} variant="top" src={"/images/games/" + game + "/default.png"}  />
                            : <Card.Img alt={getAlt({game})} variant="top" src={"/images/moregames/" + game + ".png"}  />
                         }

                        <Card.Body>
                            <Card.Title>{textToUpper({game})}</Card.Title>
                            <Card.Text>{""}</Card.Text>

                        </Card.Body>
                        </a>
                    </Card>
                </Col>
    );
}




const Minigames = () => {
	const { t } = useTranslation();
	const games = [ ];
	const moregames = [
		{"name": "spellbee", "title": ""},
		{"name": "guesser", "title": ""},
		{"name": "ladder", "title": ""},
		{"name": "chain", "title": ""},
		{"name": "wordsearch", "title": ""}
	];



	const gamesHtml =  (

		<Container>
	        <h2>Our Other Games</h2>
	        {
	            <Row>
	                {games.map(game => ( <Minigame game={game} key={game} type="in"/>))}
	                {moregames.map(game => ( <Minigame game={game["name"]} key={game} type="out"/>))}

	            </Row>
	        }
	    </Container>
    );

    return gamesHtml;
}
export default Minigames;
