import general from '../static/json/general.json';
import results from '../static/json/results.json';
import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';
import levels_pixit from '../static/json/levels_pixit.json';
import levels_calendar from '../static/json/levels_calendar.json';


export function getLevels(game) {
	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;
	var levels = general[listName].map(tset => tset.name);
	return levels;
}

function getAllLevels({allgames}) {
	var levels = {};
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		levels[game] = getLevels(game);
	}
	return levels;
}


export function getPointsNeeded(tileset, game) {

	const fourLayersSets = general["fourlayers"];
	var three_stars;

	if(game == "tiles" && fourLayersSets.includes(tileset)) {game = "fourlayers";}
	if(game == "lines") {
		three_stars = levels_lines[tileset]["best"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "shuzzle") {
		var difficulty = "easy";
		if(levels_shuzzle["epic"].includes(Number(tileset))) {difficulty = "epic";}
		if(levels_shuzzle["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_shuzzle["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "pixit") {
		var difficulty = "easy";
		if(levels_pixit["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_pixit["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "calendar") {
		var difficulty = "easy";
		if(levels_calendar["hard"].includes(Number(tileset))) {difficulty = "hard";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else {
		if(results[game]["all"]) {
			three_stars = results[game]["three"];
        } else {
            three_stars = results[game][tileset]["three"];
        }
	}
	var rule = results[game]["rule"];
	if(["tiles", "fourlayers", "pairs"].includes(game)) {
		return "To earn 3 stars, your score has to be " + three_stars + "."
	}
	if(["swapoku", "same", "segments", "calendar"].includes(game)) {
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	}
	if(rule == "bigger") {
		return "To earn 3 stars, your score has to be at least " + three_stars + "."
	} else {
		return "To earn 3 stars, your score has to be at most " + three_stars + "."
	}
}

export function getStarsRules(game,tileset) {
	var three_stars, two_stars;
	const fourLayersSets = general["fourlayers"];

	if(game == "tiles" && fourLayersSets.includes(tileset)) {game = "fourlayers";}
	if(game == "lines") {
		three_stars = levels_lines[tileset]["best"];
		two_stars = three_stars + 3;
	} else if(game == "shuzzle") {
		var difficulty = "easy";
		if(levels_shuzzle["epic"].includes(Number(tileset))) {difficulty = "epic";}
		if(levels_shuzzle["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_shuzzle["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else if(game == "pixit") {
		var difficulty = "easy";
		if(levels_pixit["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_pixit["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else if(game == "calendar") {
		var difficulty = "easy";
		if(levels_calendar["hard"].includes(Number(tileset))) {difficulty = "hard";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else {
		if(results[game]["all"]) {
			three_stars = results[game]["three"];
	        two_stars = results[game]["two"];
        } else {
            three_stars = results[game][tileset]["three"];
            two_stars = results[game][tileset]["two"];
        }
	}
	return [three_stars, two_stars];
}

export function getStarsAmount(tileset, game, points) {
	if(points == undefined || points == null) {
		return 0;
	}

	const rules = getStarsRules(game, tileset);
	const three_stars = rules[0];
	const two_stars = rules[1];

	var rule = results[game]["rule"];

	if(rule == "bigger") {
		if(points >= three_stars) {return 3;}
		if(points >= two_stars) {return 2;}
		return 1;
	} else {
		if(points <= three_stars) {return 3;}
		if(points <= two_stars) {return 2;}
		return 1;
	}
}

export function getStars(tileset, game) {
	var best = localStorage.getItem(game + "-" + tileset + "-best");
	var starsAmount = getStarsAmount(tileset, game, best);

	localStorage.setItem(game + "-" + tileset + "-stars", starsAmount);
	return starsAmount;
}

function countAllStars({allgames, levels}) {
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		var gameLevels = levels[game];
		for(var j = 0; j < gameLevels.length; j++) {
			var tileset = gameLevels[j];
			getStars(tileset, game);
		}
	}
}


export function countStars() {

	var allgames = ["tiles", "pairs", "infinite", "timed", "unpaired", "same", "lines"];
	var levels = getAllLevels({allgames});

	countAllStars({allgames, levels});
}