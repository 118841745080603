import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tilesets from './components/Tilesets';
import Moregames from './components/games/Moregames';
import Minigames from './components/games/Minigames';
import Reddit from './components/Reddit';
import ArticlesNewest from './components/ArticlesNewest';


import general from './static/json/general.json';


import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function getNewestTileset() {

	var playedPortugal = localStorage.getItem("tiles-portugal-best");
	if(playedPortugal == null) {
		return "portugal";
	}
	var tilesets = general["waiting"], ts;
	const todayDate = new Date();
	var dateDate = new Date();

	for(var i = 0; i < tilesets.length; i++) {
		ts = tilesets[i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= todayDate) {
           	return ts["name"];
        }
	}
}

function getUrl(lang) {
	var tileset = getNewestTileset();
	return ("/" + lang + "/tiles/" + tileset);
}

function imageClick(lang) {
	ReactGA.event({
		category: 'Game',
	    action: 'ImageClick'
	});
	window.location.href = getUrl(lang);
}


function MainPage({lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartGameMainPage'
	    });
	}

    return (
        <div className="mainPart">
        <Container>
            <Row>
            <><h1>{t('title')}</h1>
    	    <p className="intro">{t('shortInstruction')}</p>
    	    <div><Button variant="success" className="default-btn" href={getUrl(lang)} onClick={() => play()} >{t('play')}</Button>
    	    </div></>

    	    <br/>
    	    <a href={"/" + lang + "/tiles/tilesets"}>{t('chooseTileset')}</a>
            </Row>

        </Container>
        </div>
      );
}

function MoreInfo({lang}) {
	const { t } = useTranslation();
	const allgames =  [ "pixit", "sparkit", "shuzzle", "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];

	return (
		<>

			<Container className="moreIconTiles">
        	            <Row>
        	                <img alt="Tile1" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic1.webp"}  />
        	                <img alt="Tile2" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic2.webp"}  />
        	                <img alt="Tile3" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic3.webp"}  />
        	            </Row>
        	        </Container>
			<Container className="moreInfo" >

				<Row>
					<Col sm={9}>
						<h3>{t("tilesHeader1")}</h3>
						<p>{t("tilesText1")}</p>
					</Col>
					<Col sm={3}><img alt="example tile in tiles game" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic1.webp"} /></Col>
				</Row>
				<Row>
					<Col sm={3}><img alt="tiles game unlimited" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic2.webp"} /></Col>
					<Col sm={9}>
						<h3>{t("tilesHeader2")}</h3>
						<p>{t("tilesText2")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t("tilesHeader3")}</h3>
						<p>{t("tilesText3")}</p>
					</Col>
					<Col sm={3}><img alt="tiles game" onClick={() => imageClick(lang) } src={"/images/games/tiles/pic3.webp"} /></Col>
				</Row>
			</Container>

			<div className="mainPart">
				<Container className="instructionInfo">
					<Row>
						<Col sm={12}>
							<h3>{t("howToPlay")}</h3>
							<ol>
								<li>{t("instruction1")}</li>
								<img alt="Tiles Unlimited" src="/images/instruction/instruction1.webp"/>
								<br/><span>{t("instructionExample")} </span>
								<li>{t("instruction2")}</li>
								<img alt="Tile Game" src="/images/instruction/instruction2.webp"/>
								<li>{t("instruction3")}</li>
								<img alt="Free tile game" src="/images/instruction/instruction3.webp"/>
								<li>{t("instruction4")}</li>
								<li>{t("instruction5")}</li>
							</ol>
						</Col>
						<div><Button variant="success" className="default-btn" href={getUrl("en")}>{t('play')}</Button>
			            </div><br/>


					</Row>
				</Container>
			</div>

			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader4")}</h3>
						<p>{t("tilesText4")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader5")}</h3>
						<p>{t("tilesText5")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader6")}</h3>
						<p>{t("tilesText6")}</p>
					</Col>

				</Row>
			</Container>


		</>
	);
}

function Snow() {
	const n = 15;
	return (<>
	{
		[...Array(n)].map((e, i) => <div className="snowflake">❅</div>)
	}
	</>);
}


function Main({lang}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;
	const description = t("description");
	const title = "Unlimited Tiles - " + t("titleShort");

	console.log(lang);

	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + langBasic} />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + langBasic}  data-react-helmet="true" />

                <meta name="description" content={description}/>

                <meta property="og:image" content="https://www.unlimited-tiles.com/banner.png" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>

            </Helmet>
            <Snow />

			<MainPage lang={lang}/>
			<br/>
			<MoreInfo lang={lang}/>

			<div className="mainPart">
				<Minigames/>
            </div>
			<ArticlesNewest/>
		</>

	);
};

export default Main;
