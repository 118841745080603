import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

const NewAd = ({type}) => {


	var w = window.innerWidth;
	var adName = "";

	if(type == "tilesetsTop") {
		if(w >= 900) { adName = "Desktop_Tileset_Top"; }
		else { adName = "Mobile_Tileset_Top"; }
	}
	if(type == "tilesetsBottom") {
		if(w >= 900) { adName = "Desktop_Tileset_Bottom"; }
		else { adName = "Mobile_Tileset_Bottom";}
	}


	if(type == "top") {
		if(w >= 900) { adName = "Desktop_Game_Top"; }
		else { adName = "Mobile_Game_Top"; }
	}
	if(type == "bottom") {
		if(w >= 900) { adName = "Desktop_Game_Bottom"; }
		else { adName = "Mobile_Game_Bottom";}
	}


	if(type == "resultTop") {
		if(w >= 900) { adName = "Desktop_Result_Top"; }
		else { adName = "Mobile_Result_Top"; }
	}
	if(type == "resultBottom") {
		if(w >= 900) { adName = "Desktop_Result_Bottom"; }
		else { adName = "Mobile_Result_Bottom";}
	}

	const containerStyle = {
        textAlign: 'center'
    };
	const adStyle = {
        minHeight: '250px'
    };

	return (
        <Container style={containerStyle}>
            <span>Advertisement</span><br/>
            <div style={adStyle} data-aaad='true' data-aa-adunit={'/21718562853/unlimited-tiles.com/Unlimited_Tiles_' + adName }></div>
            <br/>
        </Container>
    );

};

export default NewAd;