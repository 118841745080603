import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Moregames from './games/Moregames';
import Badges from '../library/Badges';
import Comments from './Comments';
import '../static/css/tilesets.css';
import NewAd from './NewAd';
import GoogleAd from './GoogleAd';

import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';
import { getStars } from '../library/stars.js';
import { getLevels } from '../library/levels.js';

import general from '../static/json/general.json';
import badgesJson from '../static/json/badges.json';

function bigName(gameName) {
		const names = {
        	"calendar": "ADVENT CALENDAR",
        	"lines": "COLOR LINES",
        	"same": "SAME GAME"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}



function Tileset({game, tileset, stars, date}) {



	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	function bestScore() {
		var best = localStorage.getItem(game + "-" + tileset + "-best");

		if(best != undefined) {
			return (<>{t("bestSoFar")}: {best}</>)
		} else {
			return (<>  </>)
		}
	}

	function getUrl({game, tileset}) {
		return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
	    window.location.href = getUrl(game, tileset);
    }

	function playTileset({available, game, tileset}) {
		if(available) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	function textToUpper({game, tileset}) {
		const titles = {
        	"rubik": "RUBIK HARD",
        	"rubik3": "RUBIK EASY",
        	"bwcircles": "B&W CIRCLES",
        	"nightgarden": "NIGHT GARDEN",
        	"blacksquares": "BLACK SQUARES",
        	"whitelines": "WHITE LINES",
        	"mondrian": "PIET MONDRIAN",
        	"triumphalarch": "ARCH"
        };
        if(game == "sparkit" && tileset.includes("basic")) {
            tileset = tileset.replace("basic", "basic-");
        }
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}

	function getImage(available, game, tileset) {
		if(game === "lines") {

			if(available) {
		        return(<Card.Img variant="top" className="squareTilesetImage" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
		    } else {
		        return(<Card.Img variant="top" className="squareTilesetImage newTileset" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
	        }
		}
		if(game == "calendar" && stars == 0) {
			if(available) {
				return (<Card.Img alt={"calendar " + tileset} variant="top" src={"/images/games/calendar/" + tileset + "_closed.png"}  />);
			} else {
				return (<Card.Img alt={"calendar " + tileset} className="newTileset" variant="top" src={"/images/games/calendar/" + tileset + "_closed.png"}  />);
			}
		}

		var coverTileset = (<></>);
		if(game == "timed" || game == "infinite" || game == "pairs") {
			coverTileset = (<Card.Img style={{"position": "absolute"}} variant="top" src={"/images/games/" + game + "/cover.png"}  />);
		}
		if(game == "unpaired" || game == "timed" || game == "infinite"|| game == "pairs" ) {game="tiles";}
		if(available) {
	        return (<> {coverTileset}
	        <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  /></> );

	    } else {
	        return (<Card.Img className="newTileset" variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  />);
        }
	}

	function getStarsCode() {
		return (
			<div className="tilesetStars">
				{(stars >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

			</div>
		);
	}

	function getDifficulty(game, level) {
		if(levels_shuzzle["epic"].includes(Number(level))) {return "EPIC";}
		else if(levels_shuzzle["hard"].includes(Number(level))) {return "HARD";}
		else if(levels_shuzzle["medium"].includes(Number(level))) {return "MEDIUM";}
		else { return "EASY";}
	}

	var available = false, availableButHidden = false;

	if(date === "") {
		available = true;
	} else {
		var available = false;
        const todayDate = new Date();
        const dateDate = new Date(date);
		if(dateDate <= todayDate) {
			available = true;
		}
	}

	if(game == "calendar" && ["25", "26", "27", "28", "29","30","31"].includes(tileset) && available) {
		available = false;
		var openWindows = 0;
		for(var i = 1; i <= 24; i++) {
			if(getStars((i).toString(), game) > 0 ) {
				openWindows++;
			}
		}
		console.log(openWindows);
		if(openWindows >= 24) {
			available = true;
		} else {
			availableButHidden = true;
		}
	}

	var color = "white";
	const fourLayersSets = general["fourlayers"];
	if(game == "tiles" && fourLayersSets.includes(tileset)) {
		color = "#ccdec4";
	}

    return (
        (<Col className='tilesetContainer'>
            <Card style={{"background-color": color}} onClick={() => playTileset({available, game, tileset})}
                className={["swapoku","shuzzle","pixit","calendar"].includes(game) ? "tilesetCard bigTilesetCard" : "tilesetCard"}>
                {
                    getImage(available, game, tileset)
                }

                <Card.Body>
                    {
                        game == "swapoku"  || game == "pixit" || game == "shuzzle" || game == "calendar"
                        ? <Card.Title>{date}</Card.Title>
                        :  <><Card.Title className="tilesetName">{textToUpper({game, tileset})}</Card.Title><br/>
	                            <Card.Text className="tilesetDate">{date}</Card.Text></>
                    }
                    { getStarsCode() }
                    <Card.Text>{bestScore()}</Card.Text>
                    {
                        available
                        ? <Button className="small-btn" variant="primary"
                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                        : availableButHidden
                            ? <>Open all 24 windows to unlock this level </>
                            : <p className="levelNotAvailable">Level available from<br/> {date} </p>
					}
                </Card.Body>
            </Card>
        </Col>)

    );
}

function getTilesets(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
	var dateDate = new Date();

	for(var i = 0; i < general[listName].length; i++) {
		ts = general[listName][i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= tomorrowDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;
}

function getTilesetsAvailable(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	var dateDate = new Date();

	for(var i = 0; i < general[listName].length; i++) {
		ts = general[listName][i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= todayDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;

}

function goToGame({game}) {
	ReactGA.event({
	    category: 'Game',
	    action: 'ChangeTilesets',
	    label: game
	});
	window.location.href = "/en/" + game + "/tilesets";
}

function Icon({game, current}) {

	var addClass = "";
	if(game != current) {
		addClass = " hiddenIcon"
	}
	var gameName = game[0].toUpperCase() + game.substring(1);

	return (
		<div className={"gameIconBox" + addClass} >

			<img onClick={() => goToGame({game})} className={"gameIcon" + addClass} alt={game} variant="top" src={"/images/games/" + game + "/default.png"}  />
			<span>{gameName}</span>

		</div>
	);
}

function Snow() {
	const n = 15;
	return (<>
	{
		[...Array(n)].map((e, i) => <div className="snowflake">❅</div>)
	}
	</>);
}

function splitTilesets(tilesets, game) {
	console.log(tilesets);
	var months = [];
	for(var i = 0; i < tilesets.length; i++) {
		if(tilesets[i]["date"] != undefined) {
			var m = (tilesets[i]["date"]).substring(0,7);
			if(months.length == 0 || months[months.length-1]["month"] != m) {
				console.log(m);
				months.push({
					"month": m,
					"tilesets": [tilesets[i]],
					"stars": getStars(tilesets[i]["name"], game),
					"availableStars": 0
				});
				const todayDate = new Date();
				var dateDate = new Date(tilesets[i]["date"]);
				if(dateDate <= todayDate) {
			        months[months.length-1]["availableStars"] += 3;
			    }


				console.log(months);
				console.log(months[months.length-1]);
			} else {
				console.log(m);
				months[months.length-1]["tilesets"].push(tilesets[i]);
				months[months.length-1]["stars"] += getStars(tilesets[i]["name"], game);
				months[months.length-1]["availableStars"] += 3;
			}

		}
	}
	return months;
}


const Tilesets = ({game, language}) => {
	const { t } = useTranslation();
	var tilesets = getTilesets(game);
	var tilesetsAvailable = getTilesetsAvailable(game);

	while(tilesetsAvailable.length + 1 < tilesets.length) {
		tilesets.shift();
	}

    const [months, setMonths] = useState(splitTilesets(tilesets, game));
    const [hidden, setHidden] = useState([false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]);

	const [linkCopied, setLinkCopied] = useState(false);

	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function toggleVisibility(id) {
	    setHidden((hidden) => {
	        return {
	            ...hidden,
	            [id]: !hidden[id],
	        };
	    });

	    return true;
	}

	function monthToTitle(month) {
		var date = month.split("-");
		const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
		return monthNames[Number(date[1])-1] + " " + date[0];
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}


	function countStars(game) {
		var stars = 0;
		var tsets = getTilesets(game);

		for(var i = 0; i < tsets.length; i++) {
			var tset = tsets[i];
			var tileset = tset["name"];
			stars += getStars(tileset, game);
		}
		return stars;
	}

	function getStarsTileset({tileset, game}) {
		tileset = tileset["name"];
		return getStars(tileset, game);
	}

	function getInstruction(game) {
		var inst = {
			"tiles": "",
			//"tiles": "Look for tiles that have a common element. Score as many points as possible.",
			"pairs": "Find pairs of identical tiles.",
			"infinite": "Infinite version - tiles recreate when cleared.",
			"lines": "Draw the goal image in as few moves as possible.",
			"same": "Clear the board in as few moves as possible.",
			"unpaired": "Find the square that doesn't have a pair!",
			"timed": "You have 100 seconds to create the longest combo."
		}
		return inst[game];
	}

	function copyLink({game, setLinkCopied}) {
		ReactGA.event({
	        category: 'Game',
	        action: 'ShareResult'
	    });
	    setLinkCopied(true);
	    var link = "https://www.unlimited-tiles.com/en/" + game;
	    var textToShare = "Hi! My score in " + bigName(game) + " is ⭐ " + countStars(game) + "/" + (3*tilesetsAvailable.length) + ". Can you beat me?";

	    navigator.clipboard.writeText(textToShare + "\n\n" + link);

	}

	const allgames =  [ "calendar", "shuzzle", "pixit", "sparkit", "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];

	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Unlimited Tiles - {gameToUpper({game})} - Available Tilesets</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + gameToUpper({game}) + " - " + t(game + "Description")} />
	            <link rel="canonical" href={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:url" content={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner2.png" />
	            <meta property="og:title" content={"Unlimited Tiles - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>
			<Snow/>

	        <div className="mainPart">
		        <Container>
		            <NewAd type="tilesetsTop"/>

		        </Container>
				<br/>
		        <Container>
					<h1>All games</h1>
					<Row>
					{ allgames.map(g => ( <Icon game={g} current={game} /> )) }
					</Row>

				</Container>



                <Container >
                    <br/>
					<h1>{bigName(game)}</h1>
					{ game == "calendar"  && tilesetsAvailable.length > 0
						? <center><h5>Open all 24 calendar windows to unlock special levels starting December 25th!</h5></center>
						: <></>
					}
					<h2>
		                {
		                    tilesetsAvailable.length > 0
		                    ? <><img className="biggerStar" src={"/images/tilesets/lines/star.png"}/> { countStars(game) + " / " + (3*tilesetsAvailable.length) }
		                    <Button style={{"margin-left": "20px", "margin-bottom": "8px"}} className="small-btn" variant="primary" onClick={() => copyLink({game,setLinkCopied})}>{t("copyLink")}</Button></>
		                    : <></>
		                }


		            </h2>
		            {
		                linkCopied
		                ? <center><h4>Your results copied to the clipboard :) </h4></center>
		                : <></>
		            }


					{ game == "calendar" && tilesetsAvailable.length == 0
						? <center><h5>The Advent Calendar will start on December 1st. Every day until December 24th, you will receive a new puzzle to solve. If you open all the windows, you’ll get bonus puzzles from December 25th to 31st!</h5>
							<p><a href="/blog/christmas-tiles"> You can read more about Advent Calendar and other special Christmas games here. </a></p>
							<Row>
								<img class="card-img-top" alt="calendar game 1" style={{"width": "33%"}} src="/images/games/calendar/1_closed.png"/>
								<img class="card-img-top" alt="calendar game 2" style={{"width": "33%"}} src="/images/games/calendar/2_closed.png"/>
								<img class="card-img-top" alt="calendar game 3" style={{"width": "33%"}} src="/images/games/calendar/3_closed.png"/>

							</Row><br/>

						</center>
						: <></>
					}
		            {
						<>
		                    {
		                    months.map((month, id) =>
		                        <div className="tilesetsMonth" onClick={() => toggleVisibility(id)}>
		                        <h3 className="tilesetsMonthHeader">
		                            { monthToTitle(month["month"]) }
		                            &nbsp;&nbsp;
									<img className="monthStar" src={"/images/tilesets/lines/star.png"}/>
									&nbsp;
		                            { month["stars"]} / {month["availableStars"]}

		                            <div className="tilesetsMonthTriangle"> {
                                    	hidden[id]
                                    	? <>▽ </>
                                    	: <>△ </>
                                    } </div>
		                        </h3>
		                        {
		                            hidden[id]
		                            ? <></>
		                            : <Row>
				                        {(month["tilesets"].map(tileset => (
				                        <Tileset game={game} tileset={tileset["name"]} stars={getStarsTileset({tileset, game})}
				                                key={tileset["name"]} date={tileset["date"]} />
				                        ))
				                        )}
			                        </Row>
		                        }

		                        </div>

		                    )
		                    }

						</>


		            }

		            <NewAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>


			<Comments/>

			<Moregames/>
        </div>
      );

    return tilesetHtml
}
export default Tilesets;

