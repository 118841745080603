import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const Cookie = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Unlimited Tiles - Cookie Policy</title>
            <meta name="description" content="Cookie policy of unlimited-tiles.com" />
            <link rel="canonical" href="https://www.unlimited-tiles.com/cookie" />
        </Helmet>
        <Container>
            <h2>Cookie Policy of www.unlimited-tiles.com</h2>

            <p>This cookie policy of the www.unlimited-tiles.com website (“Cookie Policy”) stipulates the rules of using the cookies on the aforementioned website.</p>

            <p>Cookie Files (Cookies) are small information text files sent by a server and saved on the computer of a person
            browsing www.unlimited-tiles.com . You will find more details on Cookies for example at <a href="https://en.wikipedia.org/wiki/HTTP_cookie">HTTP cookie</a>.</p>

            <p>The Cookies allow to tailor www.unlimited-tiles.com to the Users’ needs. The Administrator may process data stored in the Cookies when Users browse www.unlimited-tiles.com in order to:
            identify the Users or keep anonymous statistics reflecting the way www.unlimited-tiles.com is used.</p>

            <p>Normally, the majority of web browsers available on the market accept saving the Cookies by default. The User may define the way the Cookies will be used in the settings of his/her web browser. This means that saving the Cookies may be partly limited (e.g. for a certain period of time) or disabled – in the latter case, it may influence some of the website’s functionalities.</p>

            <p>Web browser cookie settings are important as far as the consent to the Administrator’s use of the Cookies is concerned – according to the provisions of law such a consent may be also expressed through web browser settings. In the event that no such consent is expressed, web browser cookie settings have to be changed accordingly.</p>

            <p>More detailed information on changing cookie settings and the way they can be deleted by the users in the most popular web browsers are available in the “Help” section of the web browser you are currently using.</p>


            <p>Last Edited on 2024-03-28.</p>
        </Container>
        </div>
      );

    return tilesetHtml
}

export default Cookie;

