import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations for each language
import enTranslation from './locales/en.json';
import itTranslation from './locales/it.json';
import frTranslation from './locales/fr.json';
import deTranslation from './locales/de.json';

const resources = {
  en: { translation: enTranslation },
  it: { translation: itTranslation },
  fr: { translation: frTranslation },
  de: { translation: deTranslation },
};

const detectedLanguage = window.location.pathname.split('/')[1]; // Get the language from the URL

i18n.use(initReactI18next).init({
    resources,
    lng: detectedLanguage,
    fallbackLng: 'en',
    interpolation: {
            escapeValue: false,
    },
});

export default i18n;