import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


import articles from '../static/json/articles.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function Article({name, title}) {

	const navigate = useNavigate();
	const { t } = useTranslation();

	function getUrl({name}) {
    	return ("/blog/" + name);
    }

    function readArticle({name}) {

	    window.location.href = getUrl({name});
    }

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => readArticle({name})} className="tilesetCard bigTilesetCard">
                <Card.Img alt={ " Article " + title } variant="top" src={"/images/articles/" + name + ".png"}  />

                <Card.Body>
                    <h5>{title}</h5>
                    <Button className="small-btn" variant="primary"
                            href={getUrl({name})}>Read</Button>

                </Card.Body>
            </Card>
        </Col>)

    );
}


const Articles = () => {
	const twoArticles = articles["articles"].slice(0, 2);
	const articlesHTMl =  (
        <div >

        <Container>
        <br/><br/>
            <h1>Articles</h1>
            <Row>
			{
				twoArticles.map(a => (
					<Article name={a["name"]} title={a["title"]}  key={a["name"]}/>
				))
			}
			</Row>



        </Container>
        </div>
      );

    return articlesHTMl;
}

export default Articles;

